<template>
  <div class="my">
    <div class="head">
      <div class="title">
        <p>{{ $t('pay.bankCard') }}</p>
      </div>
      <div class="form">
        <el-form
          ref="ruleForm"
          :model="formData"
          :rules="rules"
          label-width="80px"
        >
          <!-- <el-dropdown size="small" trigger="click" placement="bottom-start">
            <span class="el-dropdown-link" style="cursor: pointer;">
              {{ value }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="value in areaCode" :key="value.value">{{ value.value }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
          <el-form-item prop="bankNumber">
            <el-input
              class="right_input"
              v-model="formData.bankNumber"
              :placeholder="$t('pay.inputBankCard')"
              ref="cardInput"
              @input="formatCardNumber"
              type="text"
            ></el-input>
          </el-form-item>
          <el-form-item prop="cvc">
            <el-input
              v-model="formData.cvc"
              type="text"
              maxlength="23"
              :placeholder="$t('pay.inputCode')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="time">
            <el-date-picker
              class="picker"
              v-model="formData.time"
              format="MM/yyyy"
              value-format="MM/yyyy"
              clear-icon="''"
              :editable="false"
              type="month"
              :placeholder="$t('pay.inputTime')"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div class="login">
        <el-button class="btn" @click="payBtn">{{ $t('pay.pay') }}</el-button>
      </div>
    </div>
    <p class="out" @click="outBtn">{{ $t('pay.cancel') }}</p>
  </div>
</template>

<script>
import { validateCreditCard } from '@/common/common.js'
import {
  getStripeTokenId,
  stripePay,
} from '@/api/api.js'
export default {
  props: ['id'],
  data() {
    return {
      formData: {
        bankNumber: '',
        cvc: '',
        time: '',
      },
      rules: {
        bankNumber: [
          { required: true, message: this.$t('pay.inputBankCard'), trigger: 'blur' },
          { validator: validateCreditCard, trigger: 'blur' },
          { validator: validateCreditCard, trigger: 'change' },
        ],
        cvc: [
          { required: true, message: this.$t('pay.inputCode'), trigger: 'blur' },
          { min: 3, max: 4, message: this.$t('pay.inputCodeOk'), trigger: 'blur' },
          { min: 3, max: 4, message: this.$t('pay.inputCodeOk'), trigger: 'change' }
        ],
        time: [{ required: true, message: this.$t('pay.inputTime'), trigger: 'blur' }],
      },
    }
  },
  watch: {},
  computed: {},
  created() {
  },
  methods: {
    payBtn() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          let time = this.formData.time.split('/')
          let expMonth = time[0]
          let expYear = time[1]
          const res = await getStripeTokenId({
            expMonth,
            expYear,
            bankNumber: this.formData.bankNumber,
            cvc: this.formData.cvc,
          })
            if (res.data.token) {
              const res3 = await stripePay({
                orderNo: this.id.orderNo,
                token: res.data.token,
              })
              if (res3.data.status === 'succeeded') {
                this.formData.bankNumber = ''
                this.formData.cvc = ''
                this.formData.time = ''
                this.$router.push('/nftmarket/marketPayList')
                this.$message.success(this.$t('pay.payOk'))
              } else {
                this.$message.error(this.$t('pay.payFail'))
              }
            }
        }
      })
    },
    // 为银行卡输入框添加四位数一个空格
    formatCardNumber(cardNum) {
      // 获取input的dom对象，element ui的el-input这样才能拿到
      const input = this.$refs.cardInput.$el.getElementsByTagName('input')[0]
      const cursorIndex = input.selectionStart
      const lineNumOfCursorLeft = (
        cardNum.slice(0, cursorIndex).match(/\s/g) || []
      ).length
      const noLine = cardNum.replace(/\s/g, '')
      const newCardNum = noLine
        .replace(/\D+/g, '')
        .replace(/(\d{4})/g, '$1 ')
        .replace(/\s$/, '')
      const newLineNumOfCursorLeft = (
        newCardNum.slice(0, cursorIndex).match(/\s/g) || []
      ).length
      const newCursorIndex =
        cursorIndex + newLineNumOfCursorLeft - lineNumOfCursorLeft
      this.$nextTick(() => {
        this.formData.bankNumber = newCardNum
        this.$nextTick(() => {
          input.selectionStart = newCursorIndex
          input.selectionEnd = newCursorIndex
        })
      })
    },
    outBtn() {
      this.$emit('payOutBtn')
    },
  },
}
</script>

<style lang="less" scoped>
.my {
  background: url(/img/pay2@2x.png);
  background-size: 100%;
  padding-top: 79px;
  .title {
    height: 33px;
    line-height: 33px;
  }
  /deep/ .form {
    margin-bottom: 97px;
    .el-form-item__content {
      margin-left: 0 !important;
    }
    .el-input__inner {
      font-size: 18px;
      height: 68px;
      border-radius: 34px;
      padding: 0 28px;
    }
    .el-form-item {
      margin-bottom: 18px;
    }
    .el-form-item__error {
      padding-left: 28px;
    }
    .picker .el-input__icon {
      display: none;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
  }
  .login .btn {
    margin-top: 0;
  }
}
</style>
